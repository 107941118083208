.sidebarleft{
    background-color: #fff;
    position: fixed;
    top: 60px;
    z-index: 999999;
    bottom: 0;
    height: 100vh;
    width: 240px;
    /* transform: translate3d(-100%, 0, 0); */
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.sidebarleft_full {
    background-color: #fff;
    position: fixed;
    top: 60px;
    z-index: 999999;
    bottom: 0;
    height: 100vh;
    width: 0px;
    /* transform: translate3d(-100%, 0, 0); */
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.menusicon {
    position: absolute;
    top: 16px;
    right: 0px;
    cursor: pointer;
}

.menusicon img {
    width: 25px;
}


.menusicon_two img {
    width: 25px;
}

.menusicon_two {
    display: none;
    position: absolute;
    top: 16px;
    right: -30px;
    cursor: pointer;
}


.brandname_logo{
    height:60px;
    width:100%;
    padding: 10px;
    font-family: solway;
    text-align: center;
    font-size: 20px;
}

.brandname_logo img {
    width: 90%;
    max-width: 180px;
}

.sidebar-menu img {
    width:25px;
}

.logout_side img {
    width:25px;
}


/* .sidebarleft:has(.sidebar-sec_hide) {
    background-color: #fff;
    position: fixed;
    top: 60px;
    z-index: 999;
    bottom: 0;
    height: 100vh;
    width: 240px;
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  } */

.sidebar-sec {
    background-color: #fff;
    position: fixed;
    top:0px;
    bottom: 0;
    height: 100vh;
    width: 240px;
    /* transform: translate3d(-100%, 0, 0); */
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    padding: 0px 0px;
    z-index: 999999;
  }

  .sidebar-sec_hide{
    background-color: #fff;
    position: fixed;
    top: 0px;
    bottom: 0;
    height: 100vh;
    width: 240px;
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    padding: 0px 0px;
    z-index: 9999999;
}

  li.paginate_button.page-item.active>a {
    color: #fff;
}
  
  /* ul.sidebar-menu li {
       font-family: calibri_bold;
  } */
  
  ul.sidebar-menu li ul li{
      font-family: calibri;
      border-left: 1px solid #ccc;
  }

li.main-menu.has-collapsible a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


li.main-menu.has-collapsible a span:nth-child(2){
    margin-left: 20px;
}
  
  .sidebar-menu{
      height: calc(100vh - 199px);
      overflow-y: auto;
  }
  
  .sidebar-menu,
  .sub-menu{
    padding: 0px 10px;
    margin: 8px 0px 0px 0px;
  }
  .sidebar-menu .main-menu,
  .sub-menu li{
  list-style: none;
  }
  .sidebar-menu .main-menu a{
   cursor: pointer;
   padding: 10px 10px;
   color: #1C2C49;
   font-size: 14px;
   display: block;
   position: relative;
   text-decoration: none;
    }
    .sidebar-menu a.paginate_button.current { 
        color:#FFFFFF !important 
    }



    /* .sidebar-menu .main-menu a.active:after{
        position: absolute;
        right: 0px;
            top: 20px;
        content: '\f054 ';
        font-family: 'FontAwesome';
        border-radius: 20px;
        font-size: 9px;
        width: 15px;
        color: #fff;
        text-align: center;
        height: 15px;
        line-height: 16px;
        letter-spacing: -1px;
        background-color: #9E2575;
    } */
  
  
    .sidebar-menu .main-menu a.active {
      background: #30E6C2 !important;
      border-radius: 10px;
  }
  
    .mini-sidebar .sidebar-menu .main-menu a.active:after{
        top: 12px;
        font-size: 8px;
        width: 12px;
        height: 12px;
        line-height: 13px;
    }
    .sidebar-menu .main-menu a i{
        padding-right: 8px;
        font-size: 15px;
    }
    .sub-menu{
        display: none;
        padding: 0px 0px 0px 19px !important;
        transition: all 0.35s ease;
    }
    .has-collapsible.active .sub-menu{
        display: block;
    }
    .foot-text{
        background-color: #fff;
        padding: 10px;
        font-size: 14px;
        color:#1C2C49;
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 241px;
        border-top: 1px solid #EAEAEA;
        text-align: center;
    }

    .log_power_m {
        text-align: center;
        font-size: 10px;
    }
    
    .log_com_logo_m{
        width:100%;
    }


    .logbut_log_m {
        width: 40%;
        margin: 0 auto;
        text-align: center;
    }

    .log_com_logo_m img {
        width: 80px;
    }

    .foot-text li {
        list-style-type: none;
    }

    .foot-text li a {
        list-style-type: none;
        display: flex;
        justify-content: flex-start;
        padding: 10px;
        align-items: center;
    }

    span.logouml{
        margin-left:20px;
    }

    .logout_side {
        margin-bottom: 20px;
    }



    .sidebar-sec .active img {
        filter: brightness(0) saturate(100%) invert(44%) sepia(7%) saturate(3445%) hue-rotate(197deg) brightness(90%) contrast(91%);
    }
    
    .sidebar-sec .active {
        background: #f2f2f2;
        border-radius: 5px;
    }
    
    .sidebar-sec .active a {
        color: #5f66af;
    }
  