.log_content {
    background: #fff;
}

.loginbox {
    width: 420px;
    max-width: 400px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    margin: 0px;
    background-color: rgba(255,255,255, 0.8);
    padding: 20px 30px;
    border-radius: 10px;
    border: 1px solid #ccc;
}


.brandname img {
    width: 200px;
    margin: auto;
}

.signuppo {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 10px;
}

.signup_con {
    font-size: 11px;
    text-align: center;
    margin-top: 20px;
}


.threelogin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
}

.threelogin_one {
    width: 80px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.threelogin_two {
    width: 80px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #ccc;
}


.threelogin_three {
    width: 80px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #ccc;
}


.divline {
    height: 1px;
    width: 100%;
    background: #ccc;
    margin-top: 30px;
}

.divline_or {
    text-align: center;
    font-size: 12px;
    width: 35px;
    margin: auto;
    background: #fff;
    margin-top: -9px;
    margin-bottom:15px;
}


.loginput input {
    border: 1px solid #ccc;
    margin-bottom: 12px;
    width: 100%;
    border-radius: 5px;
    height: 40px;
    padding: 10px;
}


.log_inputs_ic {
    position: relative;
    top: -43px;
    color: #b7b7b7;
    float: right;
    font-size: 22px;
    margin-right: 15px;
}

.logbut {
    width: 100%;
}

.btn_log {
    width: 100%;
    background: #5f66af !important;
    border-radius: 5px !important;
    text-transform: inherit !important;
    height: 35px !important;
    font-family: poppins !important;
}

.alreadyhave {
    font-size: 13px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

.signup_con a{
    text-decoration:underline;
    color:blue;
    cursor: pointer;
}

.alreadyhave a {
    text-decoration:underline;
    color:blue;
    cursor: pointer;
}

.frgpass {
    font-size: 12px;
    font-weight: bold;
    color: blue;
    margin-bottom: 20px;
    margin-top: 5px;
}

.frgpass a {
    color: blue;
}