.log_content {
    background: #fff;
}

.loginbox {
    width: 420px;
    max-width: 400px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    margin: 0px;
    background-color: rgba(255,255,255, 0.8);
    padding: 20px 30px;
    border-radius: 10px;
    border: 1px solid #ccc;
}


.brandname img {
    width: 200px;
    margin: auto;
}


.loginput input {
    border: 1px solid #ccc;
    margin-bottom: 12px;
    width: 100%;
    border-radius: 5px;
    height: 40px;
    padding: 10px;
}




.logbut {
    width: 100%;
    margin-top: 10px;
}

.btn_log {
    width: 100%;
    background: #5f66af !important;
    border-radius: 5px !important;
    text-transform: inherit !important;
    height: 35px !important;
    font-family: poppins !important;
}

.alreadyhave {
    font-size: 13px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
}



.alreadyhave a {
    text-decoration:underline;
    color:blue;
    cursor: pointer;
}




.forgotimg img {
    width: 150px;
    margin-top: 40px;
    margin-bottom: 10px;
}

.forgotpastex {
    font-size: 20px;
    margin-bottom: 20px;
}

.fornote {
    font-size: 12px;
    margin-bottom: 10px;
}